import Vue from 'vue';
import appServer from './_axios';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
let urlAjax= config.Uc_urlAjax;

const Api = {
	urlAjax,
   imgUrl: '/',
    ajaxRequests:(url,params) => Promise.resolve(appServer.post(urlAjax + url, params,{'headers':{'TOKEN':Api.getToken(),}})), // ajax请求
	ajaxRequestas:(url,params) => Promise.resolve(appServer.post(urlAjax + url, params,{'headers':{'TOKEN':Api.getToken(),'uid':Api.getUid(),'Content-Type':'application/x-www-form-urlencoded'}})), // ajax请求
	ajaxRequest:(url,params) => Promise.resolve(appServer.post(urlAjax + url, params,{'headers':{'TOKEN':Api.getToken(),'uid':Api.getUid()}})), // ajax请求
	Upload:(url,params) => Promise.resolve(appServer.post(urlAjax + url, params,{'headers':{'TOKEN':Api.getToken(),'uid':Api.getUid(),'contentType':false,'processData': false}})), // ajax请求

	changeTime: (timeDate) => {
		let date = new Date(timeDate);
		let year = date.getFullYear();
		let month = date.getMonth() > 9 ? date.getMonth() + 1 : '0' + date.getMonth() + 1;
		let day = date.getDate();
		return year+ '-' + month + '-' + day;
	},
	getToken:function(){
		let token=VueCookies.get('token');
		return token;
	},
	getUid:function(){
		let uid=VueCookies.get('uid');
		return uid;
	},

  getUrlParams(url,name){
    let urlList = url.substring(url.lastIndexOf("?") + 1).split('&'), // url地址分割成数组
        urlObj = {};
    for (let i = 0; i < urlList.length; i++) {
      urlObj[urlList[i].split('=')[0]] = urlList[i].split('=')[1];
    }
    return urlObj[name];
  },
	
	formDataArg(arg = {}) {
		let params = new FormData()
		for (let [k, v] of Object.entries(arg)) {
			params.set(k, v)
		}
		return params
	},
  
  
  // base64解码
  base64_decode: function(input) { // 解码，配合decodeURIComponent使用
  		const base64EncodeChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  		let output = "";
  		let chr1, chr2, chr3;
  		let enc1, enc2, enc3, enc4;
  		let i = 0;
  		input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
  		while (i < input.length) {
  			enc1 = base64EncodeChars.indexOf(input.charAt(i++));
  			enc2 = base64EncodeChars.indexOf(input.charAt(i++));
  			enc3 = base64EncodeChars.indexOf(input.charAt(i++));
  			enc4 = base64EncodeChars.indexOf(input.charAt(i++));
  			chr1 = (enc1 << 2) | (enc2 >> 4);
  			chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
  			chr3 = ((enc3 & 3) << 6) | enc4;
  			output = output + String.fromCharCode(chr1);
  			if (enc3 != 64) {
  				output = output + String.fromCharCode(chr2);
  			}
  			if (enc4 != 64) {
  				output = output + String.fromCharCode(chr3);
  			}
  		}
  		return Api.utf8_decode(output);
  	},
  
  	utf8_decode: function(utftext) { // utf-8解码
  		let string = '';
  		let i = 0;
  		let c = 0;
  		let c1 = 0;
  		let c2 = 0;
  		while (i < utftext.length) {
  			c = utftext.charCodeAt(i);
  			if (c < 128) {
  				string += String.fromCharCode(c);
  				i++;
  			} else if ((c > 191) && (c < 224)) {
  				c1 = utftext.charCodeAt(i + 1);
  				string += String.fromCharCode(((c & 31) << 6) | (c1 & 63));
  				i += 2;
  			} else {
  				c1 = utftext.charCodeAt(i + 1);
  				c2 = utftext.charCodeAt(i + 2);
  				string += String.fromCharCode(((c & 15) << 12) | ((c1 & 63) << 6) | (c2 & 63));
  				i += 3;
  			}
  		}
  		return string;
  	}
}
export default Api 